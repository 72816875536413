import { Snackbar } from "@material-ui/core";
import { Map } from "immutable";
import * as React from "react";
import styled from "styled-components";
import { CellView } from "./CellView";
import { RegularLayoutClient } from "./Layout";
import { Menu } from "./SiteHeader";
import {
  AfterCellId,
  assertUnreachable,
  Cell,
  CellAddedEvent,
  CellDeletedEvent,
  CellId,
  CellParameters,
  CellUpdatedEvent,
  CreateCalcParams,
  CreateTableParams,
  CreateTextParams,
  CreateVaryParams,
  EventNames,
  InitClientEvent,
  JsonFixDeserializedValue,
  LayoutUpdatedEvent,
  MetaEvent,
  ServerResponseEvent,
  ValueType,
} from "../../../shared/types";
import { GetRandomClientId } from "../../../shared/util";
import { WorksheetApi } from "../apiClient";

export interface WorksheetProps {
  appName: string;
  // jsonPersistence: JsonPersistence;
  // rsModule: IndivWorksheetModule;
}

const Heading = styled.div``;

export let ServerApi: WorksheetApi = null;

export const setServerApi = (api: WorksheetApi) => {
  ServerApi = api;
};

const AddCellClickableArea = styled.div`
  min-height: 2em;
  cursor: text;
  flex-grow: 1;
  color: grey;
`;

export const LayoutView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AddButtonArea = styled.div`
  display: flex;
  flex-pad
`;

const AddItem = styled.button`
  margin-right: 5px;
`;

interface WorksheetState {
  cells: Map<CellId, Cell>;
  layout: RegularLayoutClient;
  focus: { id: CellId; position: number; component: "label" | "formula" };
  // we are creating a new cell, need to get data from the server back in order to place it in the worksheet
  waitingForAddedCell: {
    clientCreationId: CellId;
    addAfterCellId: AfterCellId;
  };
  snackbarOpen: boolean;
  snackbarText: string;
  serverError: string|undefined;
  showAddMenu: boolean;
}

export type AddCellTypes = "table" | "vary" | "text" | "formula" | "chart";

export default class WorksheetView
  extends React.Component<WorksheetProps, WorksheetState> {
  constructor(props: WorksheetProps) {
    super(props);
    ServerApi.registerWorksheetChangesListener((event) =>
      this.onServerEvent(event)
    );
    this.state = {
      cells: Map<CellId, Cell>(),
      layout: new RegularLayoutClient(),
      focus: { id: null, position: null, component: null },
      waitingForAddedCell: null,
      snackbarOpen: false,
      snackbarText: "",
      serverError: undefined,
      showAddMenu: false
    };
  }
  componentDidMount(): void {
  }

  render() {
    const noCellsAdded = this.state.cells.count() == 0;
    const createCell = (type: AddCellTypes) => this.addCell("end", type);
    if (this.state.serverError != undefined) {
      return <>There was an error: {this.state.serverError}</>
    }
    return (
      <>
        <Heading>
          <Menu
            {...this.props}
            createCell={(type: AddCellTypes) => this.addCell("end", type)}
          />
        </Heading>
        <LayoutView>
          {Array.from(this.state.cells.values())
            .sort((a: Cell, b: Cell) =>
              this.state.layout.getOrderForCell(a.id) <
                  this.state.layout.getOrderForCell(b.id)
                ? -1
                : 1
            )
            .map((cell) => (
              <CellView
                cell={cell}
                key={cell.id.toString()}
                onUpdateLabel={(newLabel) =>
                  this.onUpdateLabel(cell.id, newLabel)}
                onUpdateParameters={(newParameters: CellParameters) =>
                  ServerApi.updateCellParameters(cell.id, newParameters)}
                onAddCellAfter={(id) => this.addCell(id, "text")}
                setFocusTo={this.state.focus}
                onCursorOut={(
                  curCellId: CellId,
                  position: number,
                  direction: "up" | "down" | "toLabel" | "toFormula",
                  curComponent: "formula" | "label",
                ) =>
                  this.setState((state) => {
                    let newId = curCellId;
                    let newComponent = curComponent;
                    let newPosition = position;
                    switch (direction) {
                      case "up":
                        newId = state.layout.getCellAbove(curCellId);
                        break;
                      case "down":
                        newId = state.layout.getCellBelow(curCellId);
                        break;
                      case "toFormula":
                        newComponent = "formula";
                        break;
                      case "toLabel":
                        newComponent = "label";
                        break;
                    }
                    return {
                      focus: {
                        id: newId,
                        position: newPosition,
                        component: newComponent,
                      },
                    };
                  })}
                onFocusSet={() => {
                  this.setState({
                    focus: { id: null, position: null, component: null },
                  });
                }}
                onDelete={(id: CellId) => this.deleteCell(id)}
                onMove={(id: CellId, direction: "up" | "down") =>
                  this.moveCell(id, direction)}
              />
            ))}
          <AddCellClickableArea onClick={() => this.addCell("end", "text")}>
            {noCellsAdded && "Type here to get started..."}
          </AddCellClickableArea>
          <div style={{marginLeft: "5px"}}>
            { !this.state.showAddMenu ? <button onClick={() => this.setState({showAddMenu: true})} style={{width: "80px"}}>Add</button>
             : <AddButtonArea>
              <AddItem onClick={() => {createCell("formula"); this.setState({showAddMenu: false})}}>
                Formula
              </AddItem>
              <AddItem onClick={() => {createCell("text"); this.setState({showAddMenu: false})}}>Text</AddItem>
              <AddItem onClick={() => {createCell("table"); this.setState({showAddMenu: false})}}>Table</AddItem>
              <AddItem onClick={() => {createCell("vary"); this.setState({showAddMenu: false})}}>Vary</AddItem>
            </AddButtonArea>}
          </div>
          <div>
            {/* <button onClick={() => this.setState({ snackbarOpen: true })}>Open simple snackbar</button> */}
            <Snackbar
              open={this.state.snackbarOpen}
              autoHideDuration={6000}
              onClose={() => this.setState({ snackbarOpen: false })}
              message="a thing happened"
            >
              {/* I removed the action property from <Snackbar>, not sure if that's important or not*/}
              <div>{this.state.snackbarText}</div>
            </Snackbar>
          </div>
        </LayoutView>
      </>
    );
  }

  count = 1;
  addCell(afterCellId: AfterCellId, type: AddCellTypes) {
    if (type == "chart") {
      alert("This features has not been added yet!");
      return;
    }
    const myClientCreationId = GetRandomClientId();
    this.setState((state) => {
      if (state.waitingForAddedCell) {
        throw new Error("Can't create multiple cells at once");
        // technically there's nothing stopping us from creating multiple cells at once,
        // but it is not something we need yet
      }
      return {
        waitingForAddedCell: {
          clientCreationId: myClientCreationId,
          addAfterCellId: afterCellId,
        },
      };
    }, () => {
      let parameters;
      switch (type) {
        case "text":
          parameters = CreateTextParams();
          break;
        case "formula":
          parameters = CreateCalcParams("");
          break;
        case "table":
          parameters = CreateTableParams();
          break;
        case "vary":
          parameters = CreateVaryParams("VARY(1,2,3)");
          break;
        default:
          assertUnreachable(type);
          throw new Error(
            "need to handle above to prevent waitingForAddedCell to get out of whack",
          );
      }
      ServerApi.addCell(parameters, myClientCreationId, undefined, afterCellId);
    });
  }

  displaySnack(snackbarText: string) {
    this.setState({ snackbarOpen: true, snackbarText });
  }

  moveCell(id: CellId, direction: "up" | "down") {
    ServerApi.moveCell(id, direction);
    // // TODO: optimistically update layout right now with fake event
    // this.setState((state) => ({
    //   layout: state.layout.onEvent(id, direction),
    // }));
  }

  deleteCell(id: CellId) {
    ServerApi.deleteCell(id);
  }

  onUpdateLabel(cellId: CellId, newLabel: string) {
    ServerApi.updateCellLabel(cellId, newLabel);
  }
  onServerEvent(event: ServerResponseEvent | MetaEvent) {
    /*
    maybe move this out into its own separate method/class/etc? -> would make it all more testable...
    it probably would need access to layout, and cell list?

    PersistenceListener might be a good place to do that...
    */

    if ((event as LayoutUpdatedEvent).order != undefined) {
      this.setState((state) => ({
        layout: state.layout.onEvent(event as LayoutUpdatedEvent),
      }));
    }
    switch (event.name) {
      case EventNames.INIT_CLIENT:
        this.initClient(event as InitClientEvent);
        break;
      case EventNames.CELL_UPDATED:
        this.cellUpdated(event as CellUpdatedEvent);
        break;
      case EventNames.CELL_ADDED:
        this.cellAdded(event as CellAddedEvent);
        break;
      case EventNames.CELL_DELETED:
        this.cellDeleted(event as CellDeletedEvent);
        break;
      case EventNames.DISCONNECT:
        this.serverError("disconnected from server");
        break;
      case EventNames.ERROR:
        this.serverError(event.description);
        break;
      case EventNames.LAYOUT_UPDATED:
        throw new Error("layout updated got to worksheetview")
        break;
      default: assertUnreachable(event)
    }
  }

  cellDeleted(data: CellDeletedEvent) {
    this.setState((state) => {
      return {
        cells: state.cells.delete(data.id),
      };
    });
  }
  cellUpdated(event: CellUpdatedEvent) {
    // NOTE: Because this method reads prior state and updates based on it,
    // it is using a setState updater and should not use
    // this.state/this.props.
    this.setState((state, props) => {
      const oldCell = state.cells.get(event.id);
      if (!oldCell) {
        throw new Error(`Could not find cell with id: ${event.id}`);
      }
      const updatedCell: Cell = {
        label: event.newLabel === undefined ? oldCell.label : event.newLabel,
        parameters: event.parameters === undefined
          ? oldCell.parameters
          : event.parameters,
        output: event.output === undefined
          ? oldCell.output
          : JsonFixDeserializedValue(event.output),
        id: event.id,
      };
      if (
        event.output && event.output.type == ValueType.Error &&
        event.output.stack
      ) {
        console.log("errorValue with stack: ", event.output.stack);
      }
      return { cells: state.cells.set(updatedCell.id, updatedCell) };
    });
  }

  initClient(data: InitClientEvent) {
    let cellsMap = Map(
      data.cells.map<[CellId, Cell]>((
        c,
      ) => [c.id, { ...c, output: JsonFixDeserializedValue(c.output) }]),
    );
    this.setState((state) => {
      return { 
        cells: cellsMap, 
        layout: new RegularLayoutClient(data.order), 
        serverError: undefined };
    });
  }

  cellAdded(data: CellAddedEvent) {
    this.setState((state) => {
      let stateToSet: any = {};
      const waitingForAddedCell = state.waitingForAddedCell;
      if (waitingForAddedCell?.clientCreationId === data.clientCreationId) {
        stateToSet.focus = { id: data.id, position: 0, component: "label" };
        stateToSet.waitingForAddedCell = undefined;
      }
      const updatedMap: Map<CellId, Cell> = state.cells.set(data.id, {
        ...data,
        output: undefined,
      });
      stateToSet.cells = updatedMap;
      return stateToSet;
    });
  }

  serverError(description: string) {
    this.setState((state) => {
      return {serverError:  description};
    })
  }
}
