import { Urls } from ".";
import {
  Action,
  ActionNames,
  CellId,
  CellParameters,
  EventNames,
  GetCellLabelByIdResponse,
  GetTableRowsResponse,
  GetVariationResponse,
  JsonDeserializeTableRow,
  JsonFixDeserializedValue,
  MetaEvent,
  RequestInitClientAction,
  ServerResponseEvent,
  TableRow,
  ValueId,
  Variation,
  WorksheetId,
  WorksheetListener,
} from "../../shared/types";

type EventCallback = (event: ServerResponseEvent | MetaEvent) => void;

const MAX_TIME_BETWEEN_RECONNECTS = 30000;
let curBackoff = 500;

export type WorksheetForConnection = { 
  wsId: bigint;
  access: string;
}

// Note that this only works on one single worksheet at a time - to talk to multiple worksheets,
// you'd need to create multiple instances of this class
export class WorksheetApi implements WorksheetListener {
  callbacks: EventCallback[] = [];
  socket: WebSocket;
  ws: WorksheetForConnection;
  private reconnectTimeoutId: NodeJS.Timeout;

  private constructor(socket: WebSocket, ws: WorksheetForConnection) {
    this.socket = socket;
    this.ws = ws;
    this.setupSocketListeners();
  }

  setupSocketListeners() {
    console.log("setup socket listeners", this.socket)

    this.socket.onerror = (error) => {
      console.error("websocket error", { error });
      this.onEvent({
          name: EventNames.ERROR, 
          description: "Error communicating with server",
          id: null
        })
    };
    this.socket.onmessage = (m) => {
      const data = JSON.parse(m.data);
      console.log("onmessage", { data });
      this.onEvent(data);
    };
    this.socket.onopen = () => {
      console.log("onopen");
      // reset backoff once we have a good connection
      curBackoff = 500;
      // sooo... why don't we just send request_init_client immediately?
      // that works fine for first connections, but on reconnect, the new websocket
      // really doesn't want to send that first message immediately, so I 
      // give it a little time. Definitely a hack.
      setTimeout(() => {
        const requestInitAction: RequestInitClientAction = {
          name: ActionNames.REQUEST_INIT_CLIENT,
        };
        this.sendToServer(requestInitAction);
      }, 100);
    }

    this.socket.onclose = (ev) => {
      this.onEvent({name: EventNames.DISCONNECT});
      this.socket = null;
      // we will attempt to reconnect now. Note that this simple method works
      // because if the connection attempt fails for the new socket, it will 
      // send the onclose event again, allowing this reconnect code to fire again.
      if (this.reconnectTimeoutId == null) {
        // exponential backoff
        curBackoff = Math.min(curBackoff *2, MAX_TIME_BETWEEN_RECONNECTS);
        console.log(`attempting to reconnect in ${curBackoff}ms...`)
        this.reconnectTimeoutId = setTimeout(() => {
          console.log("reconnecting")
          this.reconnectTimeoutId = null;
          this.doReconnect();
        }, curBackoff);
      }
    }
  }

  doReconnect() {
    console.log("reconnecting")
    this.socket = WorksheetApi.connect(this.ws);
    this.setupSocketListeners();
    console.log("reconnected")
  }

  static async createNewWorksheet(): Promise<WorksheetForConnection> {
    const resp = await fetch(Urls.NewWorksheet(), {
      method: "POST",
      mode: "cors",
    });
    const data = await resp.json();
    const typesFixed = {...data, wsId: BigInt(data.docId)};
    return typesFixed;
  }
  private static connect(ws: WorksheetForConnection ) {
    return new WebSocket( Urls.WebSocketUrl(ws));
  }

  static openConnectionToExistingWorksheet(ws: WorksheetForConnection) {
    const socket = WorksheetApi.connect(ws)
    return new WorksheetApi(socket, ws);
  }

  sendToServer(action: Action) {
    console.log("sending", { action });
    if (this.socket.readyState != 1) {
      console.error("websocket not ready");
      // alert("communications error with server");
      //TODO: navigate somewhere else? reconnect?
      throw new Error(
        "socket not ready when trying to send, readyState:" +
          this.socket.readyState,
      );
    }
    this.socket.send(JSON.stringify(action));
  }

  addCell(
    parameters: CellParameters,
    clientCreationId: CellId,
    forceId: CellId,
    afterCellId: CellId | "start" | "end",
  ): void {
    this.sendToServer({
      name: ActionNames.ADD_CELL,
      parameters,
      clientCreationId,
      forceId,
      afterCellId,
    });
  }

  updateCellLabel(cellId: CellId, newLabel: string) {
    this.sendToServer({
      name: ActionNames.UPDATE_CELL_LABEL,
      newLabel,
      id: cellId
    });
  }

  updateCellParameters(id: CellId, parameters: CellParameters) {
    this.sendToServer({
      name: ActionNames.UPDATE_CELL_PARAMETERS,
      id,
      parameters,
    });
  }

  deleteCell(id: CellId) {
    this.sendToServer({ name: ActionNames.DELETE_CELL, id });
  }
  moveCell(id: CellId, direction: "up" | "down") {
    this.sendToServer({ name: ActionNames.MOVE_CELL, id, direction });
  }

  onEvent(event: ServerResponseEvent | MetaEvent) {
    console.log("Worksheet Event:", event);
    if (this.callbacks.length > 0) {
      for (let callback of this.callbacks) {
        callback(event);
      }
    }
  }

  registerWorksheetChangesListener(
    callback: (event: ServerResponseEvent) => void,
  ) {
    this.callbacks.push(callback);
  }

  undo() {
    alert("todo!");
    //undo();
  }

  // Table
  async getTableRows(
    tableId: number,
    firstRow: number,
    numRows: number,
  ): Promise<Array<TableRow>> {
    const resp = await fetch(
      Urls.GetTableRows(tableId, firstRow, numRows).toString(),
      {
        method: "GET",
        mode: "cors",
      },
    );
    if (resp.status != 200) {
      console.error(resp);
      throw new Error("whoops!");
    }

    const data: GetTableRowsResponse = await resp.json();
    return data.rows.map((row) => JsonDeserializeTableRow(row));
  }

  // Vary
  async getVariation(
    varyOutputId: ValueId,
    variationIndex: number,
  ): Promise<Variation> {
    const resp = await fetch(
      Urls.GetVariation(varyOutputId, variationIndex).toString(),
      { method: "GET", mode: "cors" },
    );
    if (resp.status != 200) {
      console.error(resp);
      throw new Error("whoops!");
    }
    const data: GetVariationResponse = await resp.json();
    const ret: Variation = {
      ...data.variation,
      output: JsonFixDeserializedValue(data.variation.output),
    };
    return data.variation;
  }

  // right now trying to keep worksheet as minimal as possible
  // I actually wonder why we need this one method now..
  // Worksheet
  async getCellLabelById(id: CellId): Promise<string> {
    const resp = await fetch(
      Urls.GetCellLabelById(id, this.ws.wsId).toString(),
      { method: "GET", mode: "cors" },
    );
    if (resp.status != 200) {
      console.error(resp);
      throw new Error("whoops!");
    }
    const data: GetCellLabelByIdResponse = await resp.json();
    return data.cellLabel;
  }
}
