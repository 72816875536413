import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  CellViewProps,
  InputAutoHeightAdjust,
  InputTextArea,
  OnFormulaKeyDown,
  useFocus,
} from "./CellBasics";
import { OutputView } from "./OutputView";
import {
  CellParameters,
  CreateCalcParams,
  CreateTableParams,
  CreateVaryParams,
  ParameterType,
} from "../../../shared/types";
import { CleanFormulaString } from "../../../shared/util";

const FormulaInput = styled(InputTextArea)`
  font-size: 1em;
  width: 100%;
  overflow-x: hidden; // https://stackoverflow.com/questions/7695945/height-of-textarea-does-not-match-the-rows-in-firefox
  resize: none;
`;

const checkIfFormulaCreatesNewCell = (
  formula: string,
  existingType: ParameterType,
): false | CellParameters => {
  const cleanedUp = formula.toUpperCase().trim();
  const isVary = cleanedUp.startsWith("VARY");
  if (existingType == ParameterType.Calculation) {
    if (cleanedUp.startsWith("TABLE")) {
      return CreateTableParams();
    }
    if (isVary) {
      return CreateVaryParams(formula);
    }
  }
  if (existingType == ParameterType.Vary) {
    if (!isVary) {
      return CreateCalcParams(formula);
    }
  }
  return false;
};

export const FormulaCellView = (props: CellViewProps) => {
  const calcParams = props.cell.parameters;
  if (
    !(calcParams.type == ParameterType.Calculation ||
      calcParams.type == ParameterType.Vary)
  ) {
    throw new Error("Invalid calculation cell parameters");
  }
  const [formula, setFormula] = useState(calcParams.formula);
  const [baseParams, setBaseParams] = useState(calcParams);
  const [setFormulaFocus, formulaHtmlElRef, setFormulaSize] = useFocus();

  useEffect(() => {
    if (props.setFocusTo.id == props.cell.id) {
      if (formulaHtmlElRef.current && props.setFocusTo.component == "formula") {
        setFormulaFocus(props.setFocusTo.position);
        props.onFocusSet();
      }
    }
  }, [props.setFocusTo]);

  useEffect(() => {
    setFormulaSize();
  }, [calcParams.formula]);

  useEffect(() => {
    setFormula(calcParams.formula);
    setBaseParams(calcParams);
  }, [calcParams]);

  const isPassthroughValue = props.cell.output &&
    (props.cell.output as any).isUserEntered;

  return (
    <>
      <FormulaInput
        type="text"
        value={formula}
        onChange={(e) => {
          const formula = e.target.value;
          setFormula(formula);
          InputAutoHeightAdjust(e.target as HTMLElement);
          // users can change the cell type if they type certain keywords - we want that
          // to be instantaneous, so don't wait for them to press enter
          const newParams = checkIfFormulaCreatesNewCell(
            formula,
            baseParams.type,
          );
          if (newParams) {
            if (newParams.type == ParameterType.Table) {
              // this happens immediately since we are changing the cellview that will be displayed
              props.onUpdateParameters(newParams);
              return;
            }
            // save the new parameters so we now work from this basis
            setBaseParams(newParams);
          }
        }}
        onBlur={() => {
          // did we change anything vs what we started with?
          if (
            formula != baseParams.formula ||
            baseParams.formula != (props.cell.parameters as any).formula ||
            baseParams.type != props.cell.parameters.type
          ) {
            const cleanedFormula = CleanFormulaString(formula);
            // need to keep the existing other parameters in case this isn't a calculation cell (eg a vary)
            const newParams = { ...baseParams as any, formula: cleanedFormula };
            setFormula(cleanedFormula);
            props.onUpdateParameters(newParams);
            setBaseParams(newParams);
          }
        }}
        onKeyDown={(e) => OnFormulaKeyDown(props, e, true, formula)}
        ref={formulaHtmlElRef}
        rows={1}
        cols={30}
      />

      {!isPassthroughValue && <OutputView output={props.cell.output} />}
    </>
  );
};
