// TODO: probably stop using this and switch over to crypt.randomUUID()?
// this returns a number which would be reasonable for clients to use as the ClientCreationId when calling addCell
// doesn't strictly have to be in MAX_SAFE_INTEGER now that we are on bigint
// but it seems easier to stay inside the int32 range for math purposes
export const GetRandomClientId = () =>
  (Math.random() * Number.MAX_SAFE_INTEGER).toString();

export const CleanFormulaString = (formula: string): string => {
  formula = formula.trimStart();
  if (formula.startsWith("=")) {
    formula = formula.slice(1);
  }
  formula = formula.trimStart();
  return formula;
};
