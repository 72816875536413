import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TableCellView } from "./TableCellView";
import { TextCellView } from "./TextCellView";
import { FormulaCellView } from "./FormulaCellView";
import {
  CellViewProps,
  InputAutoHeightAdjust,
  InputTextArea,
  OnInputKeyDown,
  useFocus,
} from "./CellBasics";
import { Menu, MenuItem } from "@material-ui/core";
import {
  AnonymousCellLabelPrefix,
  ParameterType,
  TextCellFormulaPrefix,
} from "../../../shared/types";

const LabelInput = styled(InputTextArea)`
  font-size: 1em;
  width: 250px;
  margin-right: 3px;
  // added overflow hidden because there was a second line getting added in firefox. 
  // for future, need a better way to handle long names - make all names longer? give this a second line in that case?
  overflow-x: hidden; // https://stackoverflow.com/questions/7695945/height-of-textarea-does-not-match-the-rows-in-firefox
  resize: none;
`;

const CellViewStyle = styled.div`
  max-width: 800px; /* laptop specific */
  margin: 3px;
  margin-top: 0;
  display: flex;
  flex-direction: row;
`;

const ParametersAndOutput = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  width: 100%;
`;

/*const OverflowOutput = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 650px;
  margin: 3px;
  margin-top: 0;
`;*/

/*
  this is the main component responsible for rendering the contents of a cell. It looks
  at the cell's parameters and determines what kind of view needs to be created
*/
export const CellView = (props: CellViewProps) => {
  let view = props.cell.parameters.type == ParameterType.Calculation &&
      props.cell.parameters.formula.startsWith(TextCellFormulaPrefix)
    ? <TextCellView {...props} />
    : <LabeledCellView {...props} />;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMove = (direction: "up" | "down") => {
    props.onMove(props.cell.id, direction);
    handleClose();
  };
  return (
    <div>
      <CellViewStyle>
        {view}
        <CellControls>
          <i className="fas fa-ellipsis-v" onClick={openMenu}></i>
          <i
            className="far fa-trash-alt"
            onClick={() => props.onDelete(props.cell.id)}
          >
          </i>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => handleMove("up")}>Move cell up</MenuItem>
            <MenuItem onClick={() => handleMove("down")}>
              Move cell down
            </MenuItem>
          </Menu>
        </CellControls>
      </CellViewStyle>
    </div>
  );
};

const CellControls = styled.div`
 display: flex;
 flex-direction: row;
 & > i {
   padding: 5px;
   color: gray;
 }
`;

// TODO: how to handle showing "updating..." " properly?
// Implementation Options:
// * key for item =label + version # added (that'd work! per-cell version number those?) "cell last updated during.. ?"
// * don't use updating, just rely on speed. (works well now, problematic later?)
// * value in underlying model is changed to "updating" until the cell gets overwritten.
//   * this could be nice for multiple views
//   * this breaks purity -> the cell cannot be determined via equality
//   * could have the worksheet change the property on the cell at the base + update the cell in cells (use records?)
const LabeledCellView = (props: CellViewProps) => {
  const [label, setLabel] = useState(props.cell.label);
  const [lastSavedLabel, setLastSavedLabel] = useState(props.cell.label);
  const [setLabelFocus, labelHtmlElRef, setLabelSize] = useFocus();

  useEffect(() => {
    if (props.setFocusTo.id == props.cell.id) {
      if (labelHtmlElRef.current && props.setFocusTo.component == "label") {
        setLabelFocus(props.setFocusTo.position);
        props.onFocusSet();
      }
    }
  }, [props.setFocusTo]);

  //  needed for when a table is part of the initial
  // view of the page - sets size correctly
  useEffect(() => {
    setLabelSize();
  }, [props.cell.label]);

  return (
    <>
      <LabelInput
        type="text"
        value={label.startsWith(AnonymousCellLabelPrefix) ? "" : label}
        placeholder={label}
        onChange={(e) => {
          setLabel(e.target.value);
          InputAutoHeightAdjust(e.target as HTMLElement);
        }}
        onBlur={() => {
          if (lastSavedLabel != label) {
            props.onUpdateLabel(label);
            setLastSavedLabel(label);
          }
        }}
        onKeyDown={(e) =>
          OnInputKeyDown(props, "label", e, false, e.target.selectionStart)}
        ref={labelHtmlElRef}
        rows={1}
      />
      =
      <EditAndOutputView {...props} />
    </>
  );
};

const EditAndOutputView = (props: CellViewProps) => {
  let view;
  switch (props.cell.parameters.type) {
    case ParameterType.Calculation:
      view = <FormulaCellView {...props} />;
      break;
    case ParameterType.Table:
      view = <TableCellView {...props} />;
      break;
    case ParameterType.Vary:
      // eventually this might switch over to its own CellView
      view = <FormulaCellView {...props} />;
      break;
    default:
      throw new Error(
        "Unknown ParameterType for cell: " +
          JSON.stringify(props.cell.parameters, null, 2),
      );
  }
  return (
    <ParametersAndOutput>
      {view}
    </ParametersAndOutput>
  );
};
