import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  OutputNeedsOverflow,
  OutputView,
  SimpleOutputText,
} from "./OutputView";
import {
  ExpandRow,
  Link,
  OutputCell,
  OutputRow,
  Table,
} from "./TableOutputView";
import {
  AnyValue,
  Variation,
  VaryOrigin,
  VaryValue,
} from "../../../shared/types";
import { ServerApi } from "./WorksheetView";

export interface VaryOutputViewProps {
  output: VaryValue;
}

const VaryHeaderRow = styled.tr``;
const VaryHeaderCell = styled.th`
  /* shared */
  padding: 2px;
  font-size: 16px;
  text-align: center;
  font-weight: normal;

  /* version with background color/white text */
  /*
  background-color: ${(props) => props.theme.vary};
  color: ${(props) => props.theme.darkTextOnLightBackground};
  */

  /* version with only nice color as underline on the row */
  border-bottom: 2px solid ${(props) => props.theme.vary};
/*
  background-color: ${(props) => props.theme.inputAreaBackground};
  color: ${(props) => props.theme.darkTextOnLightBackground};
  */

`;
const HeaderRowChunk = ({ variation }: { variation: Variation }) => {
  const origins = variation.origins;
  // now we need to output the labels of the origins
  return (
    <VaryHeaderRow key="header">
      {origins.map((origin) => (
        <VaryHeaderCell key={origin.cellId}>
          <OriginLabel origin={origin} />
        </VaryHeaderCell>
      ))}
      <VaryHeaderCell key="value">Value</VaryHeaderCell>
    </VaryHeaderRow>
  );
};

const RowChunk = (props: { variations: Variation[]; keyStart: string }) => (
  <>
    {props.variations.map((vn: Variation, index: number) => (
      <OutputRow key={props.keyStart + index}>
        {vn.origins.map((origin) => (
          <OutputCell key={origin.varyOutputId + "-" + origin.variationIndex}>
            <OriginValue origin={origin} />
          </OutputCell>
        ))}
        <OutputCell key="val">
          <SimpleOutputText output={vn.output}></SimpleOutputText>
        </OutputCell>
      </OutputRow>
    ))}
  </>
);

interface NextPrevProps {
  curIndex: number;
  maxIndex: number;
  setIndex: (newIndex) => void;
}
const LinkIfActive = (
  props: { active: boolean; text: string; action: () => void },
) => {
  return props.active
    ? <Link onClick={props.action}>{props.text}</Link>
    : <>{props.text}</>;
};

export const VaryContainer = styled.div`
/*
  border: 2px solid ${({ theme }) => theme.vary};
  border-radius: ${({ theme }) => theme.borderRadius};
  */
  border-left: 2px solid  ${({ theme }) => theme.vary};
`;

export const VaryHeader = styled.div`
/*
  background: ${({ theme }) => theme.vary}
*/
  padding: 3px;

  */
`;

export const ComplexNextPrevControls = (props: NextPrevProps) => {
  const showPrev = props.curIndex > 0;
  const showNext = props.curIndex < props.maxIndex - 1;

  return (
    <VaryHeader>
      Vary:&nbsp;
      <LinkIfActive
        active={showPrev}
        text={"< Prev"}
        action={() => props.setIndex(props.curIndex - 1)}
      />{" "}
      |{" "}
      <LinkIfActive
        active={showNext}
        text={"Next >"}
        action={() => props.setIndex(props.curIndex + 1)}
      />
    </VaryHeader>
  );
};

const OriginLabel = ({ origin }: { origin: VaryOrigin }) => {
  const [originLabel, setOriginLabel] = useState("");
  useEffect(() => {
    ServerApi.getCellLabelById(origin.cellId).then((res) => {
      setOriginLabel(res);
    }, () => {/*error*/});
  }, [origin.cellId]);
  return <>{originLabel}</>;
};

const OriginValueView = styled.div`
  display: inline-block;
`;

const OriginValue = ({ origin }: { origin: VaryOrigin }) => {
  const [originValue, setOriginValue] = useState<AnyValue>();
  useEffect(() => {
    ServerApi.getVariation(origin.varyOutputId, origin.variationIndex).then(
      ({ origins, output }) => {
        setOriginValue(output);
      },
      () => { // error
      },
    );
  }, [origin.varyOutputId, origin.variationIndex]);
  return (
    <OriginValueView>
      {originValue && <SimpleOutputText output={originValue} />}
    </OriginValueView>
  );
};

export const ComplexOriginValueView = (props: { origins: VaryOrigin[] }) => {
  return (
    <div style={{ padding: "3px" }}>
      {props.origins.map((origin) => {
        return (
          <div key={origin.cellId.toString()}>
            <OriginLabel origin={origin} />: <OriginValue origin={origin} />
          </div>
        );
      })}
    </div>
  );
};

const ComplexVaryOutputView = (props: VaryOutputViewProps) => {
  const output = props.output as VaryValue;
  const [curVariationIndex, setCurVariationIndex] = useState(0);
  const [curVariation, setCurVariation] = useState(null as Variation);
  useEffect(() => {
    ServerApi.getVariation(output.varyId, curVariationIndex).then(
      (vn) => setCurVariation(vn),
    );
  }, [curVariationIndex, output.varyId]);

  return (
    <div>
      <ComplexNextPrevControls
        curIndex={curVariationIndex}
        maxIndex={output.numVariations}
        setIndex={setCurVariationIndex}
      />
      {curVariation
        ? (
          <>
            <ComplexOriginValueView origins={curVariation.origins} />
            <OutputView output={curVariation.output} />
          </>
        )
        : "Loading..."}
    </div>
  );
};

const SimpleVaryOutputView = (props: VaryOutputViewProps) => {
  const examples = props.output.examples;

  const [expandedRows, setExpandedRows] = useState(new Array<Variation>());
  const visibleRows = examples.length + expandedRows.length;

  const isShowMoreVisible = visibleRows < props.output.numVariations;

  const getMore = () => {
    if (!isShowMoreVisible) {
      return;
    }
    const nextVariationIndex = visibleRows;
    ServerApi.getVariation(props.output.varyId, nextVariationIndex).then(
      (newVariation) => {
        setExpandedRows(expandedRows.concat([newVariation]));
      },
    );
  };

  useEffect(() => {
    // HACK: when the vary changes, just reset the rows shown
    // it'd be nice to be able to load 5 variations at once and to refresh all the rows currently shown
    setExpandedRows([]);
  }, [props.output.varyId]);

  return (
    <Table>
      <thead>
        <HeaderRowChunk variation={examples[0]} />
      </thead>
      <tbody>
        <RowChunk variations={examples} keyStart="example" />
        <RowChunk variations={expandedRows} keyStart="expanded" />
        {isShowMoreVisible && <ExpandRow doExpand={getMore} />}
      </tbody>
    </Table>
  );
};

/*
if it's basic type: output a table
if it's a complex type: show next/previous buttons to show values
  - if a user wants to see specific values from the tables in a table, they can do a SELECT
*/

export const VaryOutputView = (props: VaryOutputViewProps) => {
  const examples = props.output.examples;
  // assume all types are the same for now
  // we use complex/simple varyoutputviews based on the
  // examples that will be shown
  return OutputNeedsOverflow(examples[0].output)
    ? <ComplexVaryOutputView output={props.output} />
    : <SimpleVaryOutputView output={props.output} />;
};
