import React, { useContext, useEffect } from "react";
import Widget from "remotestorage-widget";
import styled, { ThemeProvider } from "styled-components";
import { AddCellTypes } from "./WorksheetView";

export interface MenuProps {
  appName: string;
  createCell: (type: AddCellTypes) => void;
}

const MainSiteLink = styled.a`
  &:visited { color: black; }
  text-decoration: none;
`;


export const Menu = (props: MenuProps) => {

  return (
    <MenuBar>
      <Title>
        <MainSiteLink href="/">{props.appName}</MainSiteLink>
      </Title>
      <div style={{ marginLeft: "20px" }}></div>
      <div style={{ flexGrow: 1, marginRight:  "10px"}}></div>{" "}
      {/* this div is to force the RS widget over to right hand side of screen*/}
      <div style={{fontSize: "small", textAlign:"right", marginRight: "5px"}}>
        Copy and save the private URL to access this worksheet again
      </div>
      {
        /* if I decide to go back to icons
          <i className="fas fa-calculator" style={{fontSize: "20"}}></i>&nbsp;
          <i className="fas fa-font" style={{fontSize: "20"}}></i>&nbsp;
          <i className="fas fa-table" style={{fontSize: "20"}}></i>&nbsp;
          <i className="fas fa-chart-line" style={{fontSize: "20"}}></i>&nbsp;
          <i className="fas fa-code-branch" style={{fontSize: "20"}}></i>&nbsp;
          */
      }
    </MenuBar>
  );
};

const Title = styled.h1`
  margin: 5px;
  margin-right: 20px;
  font-size: 1.5em;
  text-align: left;
`;

const MenuBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%
  font-size: 18px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.appHighlightColor};
`;

const MenuButton = styled.button`
margin: 5px;
border-radius: 4px;
background: none;
cursor: pointer;
padding: 5px;
font-size: inherit;
&:hover {
  background: ${(props) => props.theme.buttonBackground}
}
`;

const MenuStaticItem = styled.span`
margin: 5px;
padding: 5px;
font-size: .8em;
`;

