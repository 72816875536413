import { TableOutputView } from "./TableOutputView";
import styled from "styled-components";
import React from "react";
import { VaryOutputView } from "./VaryOutputView";
import {
  AnyValue,
  assertUnreachable,
  ErrorValue,
  GetDateValueUserString,
  GetNumberValueUserString,
  NumberValue,
  ValueType,
} from "../../../shared/types";

export const OutputBaseView = styled.div`
  border: 1px solid #eeeeee;
  padding: 2px;
  cursor: default;
`;
const ErrorOutputView = styled.div`
  width: 100%;
  background: ${(props) => props.theme.error};
`;

/*
.error-circle {
    width: 70px;
    height: 70px;
    line-height: 70px;
    background: #990000;
    border-radius: 50%;
    border: 4px solid white;
    color: white;
    font-size: 45px;
    font-family:verdana;
    text-align: center;
}
<div class='error-circle'>
    <div>X</div>
</div>
*/

export interface OutputViewProps {
  output: AnyValue;
}

export interface SimpleOutputViewProps {
  output: AnyValue;
  forceDisplayPlaces?: number;
}

const NumberOutputView = styled.div`
display: flex;
flex-direction: row;
width: 100%
`;

const ActualNumber = styled.div`
  flex-grow: 1.0;
`;

const RoundingDisplay = styled.div`
color: lightgrey;
width: 10px;
`;

const NumberOnly = (props: SimpleOutputViewProps) => {
  const output = props.output as NumberValue;
  const valueDisplay = GetNumberValueUserString(
    output,
    props.forceDisplayPlaces,
  );

  const tooltip = `Value for calculations: ${output.numValue.toString()}`;

  return (
    <NumberOutputView>
      <ActualNumber title={tooltip}>{valueDisplay.str}</ActualNumber>
      <RoundingDisplay title="This value is rounded for display.&#10;The full value is used in calculation">
        {valueDisplay.isRounded ? "≈" : ""}
      </RoundingDisplay>
    </NumberOutputView>
  );
};

const NumberWithControl = (props: SimpleOutputViewProps) => {
  const valueDisplay = GetNumberValueUserString(props.output as NumberValue);

  return (
    <div style={{ display: "inline-block" }}>
      <NumberOnly {...props} />
      {/* <OutputDisplayControl output={props.output} {...valueDisplay} /> */}
    </div>
  );
};

/*
thoughts on modifying the precision of what's shown:
it does seem like the right solution is to make this a property of the cell,
 but it gets passed into the calculations that happen and that gets passed into
  the output to modify the calculation

potentially could make it a text function, eg: DECIMALS(1/3, 2) => "".33"
*/

const cleanErrorString = (errorStr: string) => {
  const errorPrefix = "#ERROR:";
  if (errorStr.startsWith(errorPrefix)) {
    errorStr = errorStr.substring(errorPrefix.length);
  }
  const formulaLocation = errorStr.indexOf("Formula: ");
  if (formulaLocation != -1) {
    errorStr = errorStr.substring(0, formulaLocation);
  }
  return errorStr;
};

export const SimpleOutputText = (props: SimpleOutputViewProps) => {
  if (!props.output) {
    return <>calculating...</>;
  }
  switch (props.output.type) {
    case ValueType.Error:
      return <>{cleanErrorString(props.output.strValue)}</>;
    case ValueType.Number:
      return <NumberOnly {...props} />;
    case ValueType.String:
      return <>{props.output.strValue}</>;
    case ValueType.Table:
      return <>Cannot show table here</>;
    case ValueType.Vary:
      return <>Cannot show vary here</>;
      case ValueType.Date:
        return <>{GetDateValueUserString(props.output)}</>;
    default:
      assertUnreachable(props.output);
  }
};

const ErrorOutput = (props: SimpleOutputViewProps) => {
  const output = props.output as ErrorValue;
  let errorStr = cleanErrorString(output.strValue);
  return <ErrorOutputView>{errorStr}</ErrorOutputView>;
};
export const CalculatingPlaceholder = () => <>calculating...</>;

export const OutputView = (props: OutputViewProps) => {
  if (!props.output) {
    return <CalculatingPlaceholder />;
  }
  switch (props.output.type) {
    case ValueType.Error:
      return <ErrorOutput {...props} />;
    case ValueType.Number:
      return (
        <OutputBaseView>
          <NumberWithControl {...props} />
        </OutputBaseView>
      );
    case ValueType.String:
      return <OutputBaseView>{props.output.strValue}</OutputBaseView>;
    case ValueType.Table:
      return <TableOutputView output={props.output} showRowZero={true} />;
    case ValueType.Vary:
      return <VaryOutputView output={props.output} />;
    case ValueType.Date: 
      return <OutputBaseView>{GetDateValueUserString(props.output)}</OutputBaseView>;
    default:
      assertUnreachable(props.output);
  }
};

const complexTypes = [ValueType.Table, ValueType.Vary];

export const OutputNeedsOverflow = (output: AnyValue) => {
  const isComplexType = complexTypes.indexOf(output.type) > -1;
  return isComplexType;
};
