import { useContext, useEffect, useRef, useState } from "react";
import styled, { withTheme } from "styled-components";
import React from "react";
import { CalculatingPlaceholder, SimpleOutputText } from "./OutputView";
import {
  ApiTableCell,
  NumberValue,
  TableRow,
  TableValue,
  ValueType,
} from "../../../shared/types";
import { ServerApi } from "./WorksheetView";

const showMoreNumRowsToAdd = 5;

export interface TableOutputViewProps {
  output: TableValue;
  showRowZero: boolean;
}

export const HeaderRow = styled.tr``;
export const HeaderCell = styled.th`
  /* shared */
  padding: 2px;
  font-size: 16px;
  text-align: center;
  font-weight: normal;

  /* version with background color/white text */
  background-color: ${(props) => props.theme.tableHeader};
  color: white;

  /* version with only nice color as underline on the row */
  /*
  border-bottom: 2px solid ${(props) => props.theme.tableHeader};
  background-color: ${(props) => props.theme.inputAreaBackground};
  color: ${(props) => props.theme.darkTextOnLightBackground};
  */
`;
export const HeaderKeyCell = styled.th`
max-width: 10ch`;

export const OutputRow = styled.tr``;
export const OutputCell = styled.td`
  border: 1px solid #eeeeee;
  padding: 2px;
  min-width: 20px;
  text-align: right;
`;
export const OutputKeyCell = styled.td`
text-align: right;
max-width: 10ch`;

export const Table = styled.table`
  border-collapse: collapse;
  cursor: default;
  /*
  border-radius: ${({ theme }) => theme.borderRadius}
  border: 2px solid ${({ theme }) => theme.tableHeader}
  */
`;

const ExpandCell = styled.td`
  text-align: center;
`;

export const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const ExpandRow = (props) => {
  return (
    <tr>
      <ExpandCell colSpan={props.numCols}>
        <Link onClick={props.doExpand}>See more</Link>
      </ExpandCell>
    </tr>
  );
};

const RowChunk = withTheme(
  (
    props: { rows: Array<TableRow>; columnDisplayPlaces: Array<number>; theme },
  ) => {
    let displayedError = false;
    return (
      <>
        {props.rows.map((row: TableRow) => (
          <OutputRow key={row.index}>
            <OutputKeyCell key="idx">
              <>{row.index}</>
            </OutputKeyCell>
            {row.columns.map((col: ApiTableCell, index: number) => {
              let style = {};
              let output = col.output;
              if (output.type == ValueType.Error) {
                style = { background: props.theme.error, border: "0" };
                // Only display the error on the first row where it's visible
                // if there is an initial without an error, but formula has an error,
                // then the error should be displayed on row 1 since row 0 was fine
                if (row.index == 0 || (row.index == 1 && !displayedError)) {
                  displayedError = true;
                } else {
                  return <OutputCell key={col.name} style={style}></OutputCell>;
                }
              }
              return (
                <OutputCell key={col.name} style={style}>
                  <SimpleOutputText
                    output={output}
                    forceDisplayPlaces={props.columnDisplayPlaces[index]}
                  />
                </OutputCell>
              );
            })}
          </OutputRow>
        ))}
      </>
    );
  },
);

const maxDisplayPlacesForColumns = (rows: TableRow[]): Array<number> => {
  let maxDisplayPlaces = [];
  let addNew = true;
  for (let row of rows) {
    for (let [idx, col] of row.columns.entries()) {
      const isNum = col.output.type == ValueType.Number;
      if (!isNum) continue;
      const numVal = col.output as NumberValue;
      if (addNew) {
        maxDisplayPlaces[idx] = numVal.displayDecimalPlaces;
      } else {
        // can't always assume each row has all the same columns defined
        const lastVal = maxDisplayPlaces[idx] === undefined
          ? 0
          : maxDisplayPlaces[idx];
        maxDisplayPlaces[idx] = Math.max(numVal.displayDecimalPlaces, lastVal);
      }
    }
    addNew = false;
  }
  return maxDisplayPlaces;
};

export const TableOutputRows = (props: TableOutputViewProps) => {
  if (!props.output) {
    return <CalculatingPlaceholder />;
  }
  const [expandedRows, setExpandedRows] = useState(new Array<TableRow>());
  const [columnDisplayPlaces, setColumnDisplayPlaces] = useState(
    maxDisplayPlacesForColumns([
      ...props.output.startPreviewRows,
      ...props.output.endPreviewRows,
    ]),
  );

  // recalculate the decimal places for each column if the table changes
  // or if we display more expandedRows
  useEffect(() => {
    setColumnDisplayPlaces(
      maxDisplayPlacesForColumns([
        ...props.output.startPreviewRows,
        ...expandedRows,
        ...props.output.endPreviewRows,
      ]),
    );
  }, [
    expandedRows,
    props.output.startPreviewRows,
    props.output.endPreviewRows,
  ]);

  // it turns out that calculating how to display all the expanded rows is kind of complicated!
  const endPreviewValid = props.output.endPreviewRows.length > 0;
  const showEndPreviewRows = endPreviewValid;
  const startPreviewValid = props.output.startPreviewRows.length > 0;
  const startPreviewLastRowIndex = startPreviewValid
    ? props.output.startPreviewRows[props.output.startPreviewRows.length - 1]
      .index
    : -1;
  const endPreviewFirstRowIndex = endPreviewValid
    ? props.output.endPreviewRows[0].index
    : -1;
  const middleRowsExist = endPreviewValid
    ? endPreviewFirstRowIndex - startPreviewLastRowIndex > 1
    : false;
  const expandedRowsExist = expandedRows.length > 0;
  const lastExpandedRowIndex = expandedRowsExist
    ? expandedRows[expandedRows.length - 1].index
    : -1;
  const showExpand = middleRowsExist &&
    lastExpandedRowIndex < endPreviewFirstRowIndex - 1;
  const maximumExpandedRows = middleRowsExist
    ? endPreviewFirstRowIndex - startPreviewLastRowIndex
    : 0;

  const getMoreRows = () => {
    if (!endPreviewValid || !showExpand) {
      return;
    }
    const firstToRetrieve = expandedRowsExist
      ? lastExpandedRowIndex + 1
      : startPreviewLastRowIndex + 1;
    let maxRow = Math.min(
      firstToRetrieve + showMoreNumRowsToAdd,
      endPreviewFirstRowIndex,
    );
    ServerApi.getTableRows(
      props.output.valueId,
      firstToRetrieve,
      maxRow - firstToRetrieve,
    ).then(
      (rows) => {
        // this is getting called with what seems to be valid stuff.
        //console.log(rows);
        setExpandedRows(expandedRows.concat(rows));
      },
    );
  };

  // re-retrieve all the rows if the table being shown is changed
  // note that when the number of rows in the table decreases, we cannot trust
  // expandedRows to be the correct size since it is updated *after* this effect runs
  // for example: the user may have chosen to display
  // 5 rows in a 15 row table, but table size reduces to 7 rows, there's not 5
  // expanded rows to show any more. But expandedRows lags behind, so it'll
  // still have 5 rows in it until we re-retrieve them
  useEffect(() => {
    // the number of rows to display in expanded section can go down
    // if the overall table size decreases
    const numRowsToRetrieve = Math.min(
      maximumExpandedRows,
      expandedRows.length,
    );
    // we're not showing any expanded rows, don't need to refresh them
    if (!middleRowsExist || numRowsToRetrieve <= 0) {
      // delete expandedRows in case the table size decreased
      setExpandedRows([]);
    }
    ServerApi.getTableRows(
      props.output.valueId,
      startPreviewLastRowIndex + 1,
      numRowsToRetrieve,
    ).then(
      (rows) => setExpandedRows(rows),
    );
  }, [props.output.valueId]);

  const startPreviewRowsToShow = props.showRowZero
    ? props.output.startPreviewRows
    : props.output.startPreviewRows.slice(1);

  return (
    <>
      <RowChunk
        rows={startPreviewRowsToShow}
        columnDisplayPlaces={columnDisplayPlaces}
      >
      </RowChunk>
      <RowChunk rows={expandedRows} columnDisplayPlaces={columnDisplayPlaces}>
      </RowChunk>
      {showEndPreviewRows && (
        <>
          {showExpand && (
            <ExpandRow numCols={9999} doExpand={getMoreRows}></ExpandRow>
          )}
          <RowChunk
            rows={props.output.endPreviewRows}
            columnDisplayPlaces={columnDisplayPlaces}
          >
          </RowChunk>
        </>
      )}
    </>
  );
};

export const TableOutputView = (props: TableOutputViewProps) => {
  if (!props.output) {
    return <CalculatingPlaceholder />;
  }
  let colNames = [];
  let firstRow = props.output.startPreviewRows[0];
  if (firstRow) {
    colNames = firstRow.columns.map((col) => col.name);
  }
  colNames.unshift("Row"); // row number column
  return (
    <Table style={{ width: "100%" }}>
      <thead>
        <HeaderRow>
          {colNames.map((name) => <HeaderCell key={name}>{name}</HeaderCell>)}
        </HeaderRow>
      </thead>
      <tbody>
        <TableOutputRows {...props} />
      </tbody>
    </Table>
  );
};
