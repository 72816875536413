import React, { useEffect, useRef, useState } from "react";
import { CellViewProps } from "./CellBasics";
import {
  HeaderCell,
  HeaderKeyCell,
  HeaderRow,
  OutputCell,
  OutputKeyCell,
  OutputRow,
  Table,
  TableOutputRows,
  TableOutputView,
} from "./TableOutputView";
import styled from "styled-components";
import { OutputView } from "./OutputView";
import {
  ComplexNextPrevControls,
  ComplexOriginValueView,
  VaryContainer,
} from "./VaryOutputView";
import {
  AnyValue,
  Column,
  CreateErrorValue,
  CreateNewColumn,
  CreateStringValue,
  TableParameters,
  TableValue,
  ValueType,
  Variation,
  VaryValue,
} from "../../../shared/types";
import { CleanFormulaString } from "../../../shared/util";
import { ServerApi } from "./WorksheetView";

/*

This component allows for viewing and editing of tables

*/

const CellEdit = styled.input`
width: 100%;
background: ${(props) => props.theme.inputAreaBackground};
border: none;
/*border-radius: 4px; */
font-size: inherit;
font-family: ${(props) => props.theme.inputFontFamily};
/*&:placeholder { } */
color: inherit;
font-weight: inherit;
`;

const AddCell = styled.td`
border: none;
`;

const AddButton = styled.button`
cursor: pointer;
`;

const Parameter = styled.div`
padding-bottom: 5px;
`;

const TableSettingsSection = styled.div`
  /*border-left: 2px solid ${(props) => props.theme.settingsColor};
  padding: 5px;
  display: flex;*/
`;

const InputForEditing = (
  props: {
    isFormula: boolean;
    initialValue: string;
    updateValue: (s: string) => void;
    placeholderText?: string;
  },
) => {
  // if initialValue is undefined, then the input's value field is undefined and react viewed it as
  // uncontrolled and you get an error when value starts being defined (at which point it is controlled).
  // Defining a string from the start ensures it's always controlled.
  const [val, setVal] = useState(props.initialValue ?? "");
  return (
    <CellEdit
      type="text"
      value={val}
      onChange={(e) => setVal(e.target.value)}
      placeholder={props.placeholderText ?? ""}
      onBlur={() => {
        let valToSave = val;
        if (props.isFormula) {
          valToSave = CleanFormulaString(val);
          setVal(valToSave);
        }
        if (valToSave != props.initialValue) {
          props.updateValue(valToSave);
        }
      }}
    />
  );
};

const updateColumn = (idx: number, newColValues: {}, props: CellViewProps) => {
  const params = props.cell.parameters as TableParameters;
  const newCol = { ...params.columns[idx], ...newColValues };
  props.onUpdateParameters({
    ...params,
    columns: updateSingleColumn(idx, newCol, params.columns),
  });
};

// note that the text is rendered exactly as shown below, so tabbing in the lines will cause them to be indented in the tooltip the user sees.
const startRowHoverText =
  `When a column formula uses the prev keyword to read the value of the previous row, you need to define a starting value that will be used for row 0.

This field is optional.`;

const InitialAndFormulaRows = (props: CellViewProps) => {
  const params = props.cell.parameters as TableParameters;
  return (
    <>
      <OutputRow key="row0Formula">
        <OutputKeyCell key="keyCol">
          Start{" "}
          <span style={{ fontSize: "12px" }}>
            <i className="fas fa-question-circle" title={startRowHoverText}></i>
          </span>
        </OutputKeyCell>
        {params.columns.map((col, idx) => {
          return (
            <OutputCell key={"" + idx + col.initial}>
              <InputForEditing
                isFormula={true}
                initialValue={col.initial}
                updateValue={(newVal) =>
                  updateColumn(idx, { initial: newVal }, props)}
                placeholderText={"First row formula (optional)"}
              />
            </OutputCell>
          );
        })}
      </OutputRow>
      <OutputRow key="normalRowformula">
        <OutputKeyCell key="keyCol">Formula</OutputKeyCell>
        {params.columns.map((col, idx) => (
          <OutputCell
            key={"" + idx + col.formula}
            style={{ borderBottom: "1px solid black" }}
          >
            <InputForEditing
              isFormula={true}
              initialValue={col.formula}
              updateValue={(newVal) =>
                updateColumn(idx, { formula: newVal }, props)}
              placeholderText={"Entire column formula"}
            />
          </OutputCell>
        ))}
      </OutputRow>
    </>
  );
};

const updateSingleColumn = (
  columnIndex: number,
  newCol: Column,
  cols: Column[],
): Column[] => {
  return cols.slice(0, columnIndex).concat(newCol).concat(
    cols.slice(columnIndex + 1),
  );
};

const addColumn = (newCol: Column, cols: Column[]): Column[] => {
  return cols.concat([newCol]);
};

const useVaryOutput = (rawOutput: AnyValue): [JSX.Element, AnyValue] => {
  const [curVariationIndex, setCurVariationIndex] = useState(0);
  const [curVariation, setCurVariation] = useState(null as Variation);
  const output = rawOutput as VaryValue;
  useEffect(() => {
    if (output && output.varyId) {
      ServerApi.getVariation(output.varyId, curVariationIndex).then(
        (vn) => setCurVariation(vn),
      );
    }
  }, [curVariationIndex, output?.varyId]);
  // keep hooks above here to ensure consistent # of hooks during render
  if (rawOutput?.type != ValueType.Vary) {
    return [null, null];
  }

  return [
    <VaryContainer>
      <ComplexNextPrevControls
        curIndex={curVariationIndex}
        maxIndex={output.numVariations}
        setIndex={setCurVariationIndex}
      />
      {curVariation && (
        <ComplexOriginValueView
          origins={curVariation.origins}
        />
      )}
    </VaryContainer>,
    curVariation ? curVariation.output : CreateStringValue("Loading..."),
  ];
};

export const TableCellView = (props: CellViewProps) => {
  const rawOutput = props.cell.output;
  const params = props.cell.parameters as TableParameters;
  const onAddColumn = () =>
    props.onUpdateParameters({
      ...params,
      columns: addColumn(CreateNewColumn(), params.columns),
    });
  const [length, setLength] = useState(params.length);
  const [varyControls, varyOutput] = useVaryOutput(props.cell.output);

  let stringOutput;
  let tableOutput;
  if (rawOutput?.type == ValueType.Error) {
    stringOutput = props.cell.output;
  } else if (!rawOutput) {
    stringOutput = CreateErrorValue("There was an error loading your table");
    console.log("There was no output defined for a table! props were:", props);
  } else if (varyOutput) {
    switch (varyOutput.type) {
      case ValueType.Table:
        tableOutput = varyOutput;
        break;
      case ValueType.String:
        stringOutput = varyOutput;
        break;
      default:
        throw new Error("unknown return from vary inside of a table");
    }
  } else if (rawOutput?.type == ValueType.Table) {
    tableOutput = rawOutput;
  } else {
    console.log("unknown table output type: ", rawOutput);
    throw new Error("unknown table output");
  }

  return (
    <>
      <TableSettingsSection>
        <Parameter>Table</Parameter>
        <Parameter>
          Length:{" "}
          <input
            type="text"
            size={5}
            value={length}
            onChange={(e) => setLength(e.target.value)}
            onBlur={() => props.onUpdateParameters({ ...params, length })}
          />
        </Parameter>
        <Parameter>
          {varyControls}
        </Parameter>
      </TableSettingsSection>
      {/* that's right! an actual table. To build our... table!*/}
      <Table>
        <thead>
          <HeaderRow>
            <HeaderKeyCell key="key"></HeaderKeyCell>
            {params.columns.map((col, idx) => (
              <HeaderCell key={col.name + idx}>
                {
                  <InputForEditing
                    isFormula={false}
                    initialValue={col.name}
                    updateValue={(newVal) =>
                      updateColumn(idx, { name: newVal }, props)}
                    placeholderText="Column name"
                  />
                }
              </HeaderCell>
            ))}
            <AddCell>
              <AddButton onClick={onAddColumn}>+</AddButton>
            </AddCell>
          </HeaderRow>
        </thead>
        <tbody>
          <InitialAndFormulaRows {...props} />
          {tableOutput && (
            <TableOutputRows
              output={tableOutput as TableValue}
              showRowZero={true}
            />
          )}
          {stringOutput &&
            (
              <OutputRow>
                <OutputKeyCell></OutputKeyCell>
                <OutputCell colSpan={params.columns.length}>
                  <OutputView output={stringOutput} />
                </OutputCell>
              </OutputRow>
            )}
        </tbody>
      </Table>
    </>
  );
};
