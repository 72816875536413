import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  AnonymousCellLabelPrefix,
  Cell,
  CellId,
  CellParameters,
  CreateCalcParams,
  TextCellFormulaPrefix,
} from "../../../shared/types";

export const CreateTextCellFormula = (text: string): string => {
  return TextCellFormulaPrefix + " " + text;
};

export interface CellViewProps {
  cell: Cell;
  onUpdateLabel: (newLabel: string) => void;
  onUpdateParameters: (newParams: CellParameters) => void;
  onAddCellAfter: (id: CellId) => void;
  setFocusTo: { id: CellId; position: number; component: "label" | "formula" };
  onCursorOut: (
    id: CellId,
    position: number,
    direction: "up" | "down" | "toFormula" | "toLabel",
    curComponent: "formula" | "label",
  ) => void;
  onFocusSet: () => void;
  onDelete: (id: CellId) => void;
  onMove: (id: CellId, direction: "up" | "down") => void;
}

export const InputTextArea = styled.textarea`
/* shared */
padding: 2px;
font-family: ${(props) => props.theme.inputFontFamily};


/* version: light grey background*/ 

border: 0;
background: ${(props) => props.theme.inputAreaBackground};
/*border-radius: 4px;*/


/* version: light grey rounded background */
/*
border-radius: 4px;
border: 1px solid rgba(0,0,0,.1);
*/

/* version: left side oriented line */
`;

export const useFocus = () => {
  const htmlElRef = useRef<HTMLElement>();
  const setFocus = (position: number) => {
    const currentEl = htmlElRef.current as HTMLTextAreaElement;
    if (currentEl) {
      currentEl.focus();
      if (position !== undefined) {
        currentEl.setSelectionRange(position, position);
      }
    }
  };
  const setSize = () => {
    const currentEl = htmlElRef.current;
    if (currentEl) {
      currentEl.style.height = "auto";
      currentEl.style.height = currentEl.scrollHeight + "px";
    }
  };
  return [setFocus, htmlElRef, setSize] as const;
};

export const InputAutoHeightAdjust = (el: HTMLElement) => {
  /* as the user adds/removes new lines, this will make
     sure the text area is always the correct height to
     match the number of lines. */

  // always auto grow/auto shrink
  // setting height to auto first allows the textarea to shrink
  el.style.height = "auto";
  el.style.height = el.scrollHeight + "px";
};

/* text areas don't have uniform line  length, and lines can be ended either by
  a \n or by exceeding the cols value */
const isPositionOnLastTextAreaLine = (
  val: string,
  selStart: number,
  cols: number,
): boolean => {
  /* idea 1: keeping around in case I decide I'd like to switch later.
    let pos = 0;
    while (pos < selStart && pos < val.length) {
      let nextRowByCols = val.slice(pos, pos + 20);
      let returnLocation = nextRowByCols.indexOf("\n");
      if (returnLocation != -1) {
        pos = pos + returnLocation;
      } else {
        pos = pos + 20;
      }
    }
    // okay, position is now officially past selStart - are we also past the end of the string?
    // if so, we were at the last line
    return pos >= val.length;*/

  // idea 2: walk backwards from the end of the string
  // if we encounter col # of characters OR  we  encounter a \n before selStart, then
  // that's the last line
  let earliestPossibleStartOfLastRow = val.length - 20;
  if (selStart < earliestPossibleStartOfLastRow) {
    return false;
  }
  //let lastRowByCount = val.slice(earliestPossibleStartOfLastRow);
  let returnPos = val.lastIndexOf("\n");
  if (returnPos > selStart) {
    return false;
  }
  return true;
};

const isPositionOnFirstTextAreaLine = (
  val: string,
  selStart: number,
  cols: number,
): boolean => {
  let isBeforeEndOfColumns = selStart < cols;
  let returnCharPresent = val.indexOf("\n") != -1;
  let isBeforeFirstReturnChar = true;
  if (returnCharPresent) {
    isBeforeFirstReturnChar = selStart <= val.indexOf("\n");
  }

  return isBeforeEndOfColumns && isBeforeFirstReturnChar;
};

const deleteEqualSign = (props: CellViewProps, currentFormula: string) => {
  let oldLabel = props.cell.label;
  if (oldLabel.startsWith(AnonymousCellLabelPrefix)) {
    oldLabel = "";
  }
  props.onUpdateLabel(""); // this will reset it back to the anonymous cell label
  props.onUpdateParameters(
    CreateCalcParams(CreateTextCellFormula(oldLabel + currentFormula)),
  );
  props.onCursorOut(props.cell.id, oldLabel.length, "toFormula", "formula");
};

export const OnFormulaKeyDown = (
  props: CellViewProps,
  e: KeyboardEvent,
  allowNewLine: boolean,
  currentFormula: string,
) => {
  let el = e.target as HTMLTextAreaElement;
  if (e.key == "Backspace" && el.selectionStart == 0) {
    e.preventDefault();
    // merge the formula and label together into a TEXTCELL
    deleteEqualSign(props, currentFormula);
  } else {
    const characterPosition = (e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement)
      ? e.target.selectionStart
      : 0;
    OnInputKeyDown(props, "formula", e, allowNewLine, characterPosition);
  }
};

export const OnInputKeyDown = (
  props: CellViewProps,
  component: "label" | "formula",
  e: KeyboardEvent,
  allowNewLine: boolean,
  characterPosition: number,
) => {
  let el = e.target as HTMLTextAreaElement;

  let curRow = Math.floor(el.selectionStart / el.cols);
  let isLastRow = isPositionOnLastTextAreaLine(
    el.value,
    el.selectionStart,
    el.cols,
  );

  let isFirstRow = isPositionOnFirstTextAreaLine(
    el.value,
    el.selectionStart,
    el.cols,
  );
  let charsBeforeCursor = el.value.slice(0, el.selectionStart).trimEnd();
  let containsMultilineStart = charsBeforeCursor.indexOf(":") != -1;
  let containsMultilineEnd = charsBeforeCursor.indexOf(";") != -1;

  if (e.key == "Enter") {
    if (!allowNewLine) {
      e.preventDefault();
    } else if (!containsMultilineStart || containsMultilineEnd) {
      props.onAddCellAfter(props.cell.id);
      e.preventDefault();
    }
  }
  if (e.key == "ArrowUp" && isFirstRow) {
    e.preventDefault(); // not strictly necessary, but doing for good measure
    props.onCursorOut(props.cell.id, characterPosition, "up", component);
  }
  if (e.key == "ArrowDown" && isLastRow) {
    e.preventDefault(); // not strictly necessary, but doing for good measure
    props.onCursorOut(props.cell.id, characterPosition, "down", component);
  }
};
