/* colors based off of: https://material-ui.com/customization/palette/ */
// these are "light" colors
export const mainTheme = {
  appHighlightColor: "#81c784",
  buttonBackground: "#eeeeee",
  tableHeader: "#4791db",
  error: "#fab9bc", /* "#e57373"*/
  vary: "#ffb74d", /*"#81c784"*/
  lightTextOnDarkBackground: "white",
  darkTextOnLightBackground: "black",
  inputAreaBackground: "none", /*"#eeeeee"*/
  inputFontFamily: "roboto",
  settingsColor: "#d5d5d5",
  borderRadius: "4px",
};
