import {
  ActionNames,
  AddCellAction,
  AfterCellId,
  CellId,
  EventNames,
  LayoutUpdatedEvent,
  MoveCellAction,
  ServerResponseEvent,
  WorksheetListener,
} from "../../../shared/types";

export class RegularLayoutClient implements WorksheetListener {
  private readonly order: Array<CellId>;
  constructor(order?: Array<CellId>) {
    this.order = order ? order : [];
  }
  onEvent(event: ServerResponseEvent): RegularLayoutClient {
    if (event.name == EventNames.LAYOUT_UPDATED) {
      return new RegularLayoutClient(event.order);
    }

    // many other types of events can contain layout order info, so
    // check to see if it's there.
    const orderEvent = event as unknown as LayoutUpdatedEvent;
    if (orderEvent.order != undefined) {
      return new RegularLayoutClient(orderEvent.order);
    }
    return this;
  }
  getOrderForCell(id: CellId): number {
    const ret = this.order.indexOf(id);
    if (ret == -1) {
      // this used to throw, but in a world where there are multiple clients,
      // I think it makes sense to have this be a warning instead since it's
      // easier for there to be race conditions
      console.warn("Could not determine order for cellId: " + id);
      return this.order.length;
    }
    return ret;
  }
  getCellAbove(id: CellId): CellId {
    const index = this.getOrderForCell(id);
    return this.order[Math.max(0, index - 1)];
  }
  getCellBelow(id: CellId): CellId {
    const index = this.getOrderForCell(id);
    return this.order[Math.min(this.order.length - 1, index + 1)];
  }
  getAddCellAction(
    action: AddCellAction,
    afterCellId: AfterCellId,
  ): AddCellAction {
    return {
      ...action,
      afterCellId,
    };
  }
  getMoveCellAction(id: CellId, direction: "up" | "down"): MoveCellAction {
    return {
      name: ActionNames.MOVE_CELL,
      direction,
      id,
    };
  }
}
