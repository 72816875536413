import { CssBaseline } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { render } from "react-dom";
import {
  HashRouter,
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { AppName, CellId, ValueId, WorksheetId } from "../../shared/types";
import WorksheetView, {
  ServerApi,
  setServerApi,
} from "./components/WorksheetView";
import { mainTheme } from "../themes";
import { WorksheetApi, WorksheetForConnection } from "./apiClient";

const App = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const FillingWorksheetView = styled.div`
  flex-grow: 1;
`;

interface AppProperties {
  appName: string;
}

// TODO: should we hardcode the prod domain?
const webBaseUrl = window.location.origin;
const apiBaseUrl = window.location.hostname == "localhost" ? "http://localhost:3000" : window.location.origin;

export const Urls = {
  Worksheet: (ws:WorksheetForConnection) => `/ws/${ws.wsId}/${ws.access}/`,
  Root: "/",
  WebSocketUrl: (ws: WorksheetForConnection) => {
    const webSocketUrl = new URL(apiBaseUrl);
    webSocketUrl.protocol = webSocketUrl.protocol === "https:" ? "wss:" : "ws:";
    return new URL(`/start_web_socket?wsId=${ws.wsId}&access=${ws.access}`, webSocketUrl);
  },
  GetTableRows: (tableId: number, firstRow: number, numRows: number) => {
    const url = new URL("/getTableRows", apiBaseUrl);
    url.searchParams.set("tableId", tableId.toString());
    url.searchParams.set("firstRow", firstRow.toString());
    url.searchParams.set("numRows", numRows.toString());
    return url;
  },
  NewWorksheet: () => {
    return new URL("/new", apiBaseUrl);
  },
  GetVariation: (varyOutputId: ValueId, variationIndex: number) => {
    const url = new URL("/getVariation", apiBaseUrl);
    url.searchParams.set("varyOutputId", varyOutputId.toString());
    url.searchParams.set("variationIndex", variationIndex.toString());
    return url;
  },
  GetCellLabelById: (id: CellId, wsId: WorksheetId) => {
    const url = new URL("/getCellLabelById", apiBaseUrl);
    url.searchParams.set("cellId", id.toString());
    url.searchParams.set("wsId", wsId.toString());
    return url;
  },
};

const clientStart = async (ws: WorksheetForConnection) => {
  window.document.title = AppName;
  setServerApi(WorksheetApi.openConnectionToExistingWorksheet(ws));

  return {
    appName: AppName,
  };
};

const WorksheetHost: FC = () => {
  let { wsId: wsIdStr, access } = useParams();
  if (!wsIdStr || !access) {
    return <>Could not load that worksheet!</>;
  }
  const ws = {wsId: BigInt(wsIdStr), access};

  const [appProps, setAppProps] = useState<AppProperties>();
  useEffect(() => {
    clientStart(ws).then((appProperties) =>
      setAppProps(appProperties)
    );
  }, []);
  return (
    <>
      {appProps
        ? (
          <FillingWorksheetView>
            <WorksheetView {...appProps} />
          </FillingWorksheetView>
        )
        : "loading..."}
    </>
  );
};

const WSChoose: FC = () => {
  let navigate = useNavigate();

  const doCreate = async () => {
    const ws = await WorksheetApi.createNewWorksheet();
    navigate(Urls.Worksheet(ws));
  };

  return (
    <>
      <div style={{ padding: "5px" }}>
        Welcome to Qrunch - a new type of spreadsheet. Let's qrunch the numbers!
        <div style={{paddingTop:"20px"}}>
          <input type="button" onClick={() => doCreate()} value="Create new worksheet">
          </input>
        </div>
      </div>
    </>
  );
};

render(
  <App>
    <CssBaseline />
    <ThemeProvider theme={mainTheme}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<WSChoose />} />
          <Route path="/ws/:wsId/:access/" element={<WorksheetHost />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Sorry! We couldn't find what you're looking for!</p>
              </main>
            }
          />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  </App>,
  document.getElementById("root"),
);

// api.addCell("firstpost", "2", 1);
/*
api.addCell(
  "letable",
  `TABLE(18):
  b=4
  c[0]=1
  c=9999999999999
a[0]=1
a=prev+1;`
);
api.addCell(
  "letable2",
  `TABLE(3):
  b=4
  c[0]=1
  c=9999999999999
a[0]=1
a=prev+1;`
);
api.addCell(
  "letable3",
  `TABLE(6):
  b=4
  c[0]=1
  c=9999999999999
a[0]=1
a=prev+1;`
);
*/
/*
api.addCell("myVary", "VARY(1,2,3,4)");
api.addCell(
  "mahTable",
  `TABLE(3):
  a[0]=myVary
  a=prev*10
  b=a+12;`
);
api.addCell("lastword", "3");
api.addCell("bleepbloop", "mahTable.a[0]");


*/

// const initialSetup = (api: Api) => {
//   let currentClientId = 0;
//   api.addCell("randoEmptyTable", CreateTableParams(), 8);

//   api.addCell("savings_at_retirement", CreateCalcParams("savings_over_time.savings[last]"), 7);
//   /*
//   api.addCell("savings_over_time",
//     CreateTableParams(20, [
//       { name: "year", formula: "prev+1", initial: "2021" },
//       { name: "invested_this_year", formula: "income+(income*savings_rate)" },
//       { name: "savings", initial: "$0", formula: "prev+prev*stock_rate_of_return+invested_this_year" }]),
//     5);
//   */
//   api.addCell(
//     "savings_over_time",
//     CreateTableParams(20, [
//       { name: "year", formula: "prev+1", initial: "2021" },
//       { name: "invested_this_year", formula: "income+(income*savings_rate)" },
//       { name: "interest", formula: "savings[prev]*5%" },
//       { name: "savings", initial: "$0", formula: "prev+prev*stock_rate_of_return+invested_this_year" },
//     ]),
//     5,
//   );
//   /* api.addCell("stock_rate_of_return", CreateCalcParams("5%"), 1); */
//   api.addCell("stock_rate_of_return", CreateVaryParams("VARY(5%, 10%)"), 1);

//   /*api.addCell("savings_rate", CreateCalcParams("20%"), 2); */
//   api.addCell("savings_rate", CreateVaryParams("VARY(5%, 10%, 20%)"), 1);
//   api.addCell("income", CreateCalcParams("$100_000"), 4);

//   /*
//   const addUnits = () => {
//     api.addCell("firstpost", CreateCalcParams("2"), 1);
//     api.addCell("dollarbucks", CreateCalcParams("$35"), 2);
//     api.addCell("", CreateCalcParams("$35M+$1M"), 2);
//     api.addCell("", CreateCalcParams("$35.50+$1"), 2);
//     api.addCell("", CreateCalcParams("dollarbucks+1"), 2);
//     api.addCell("percent", CreateCalcParams("5%"), 2);
//     api.addCell("", CreateCalcParams("5.5%"), 2);
//     api.addCell("test", CreateCalcParams("$35"), 2);
//     api.addCell("", CreateCalcParams("0.444"), 4);
//     api.addCell("", CreateCalcParams("1/3"), 5);
//   }*/
// };
// // initialSetup(appProperties.api);
