import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  CellViewProps,
  CreateTextCellFormula,
  InputAutoHeightAdjust,
  InputTextArea,
  OnFormulaKeyDown,
  useFocus,
} from "./CellBasics";
import {
  CalculationParameters,
  CreateCalcParams,
  TextCellFormulaPrefix,
} from "../../../shared/types";

const TextInput = styled(InputTextArea)`
  width: 100%;
  margin-right: 3px;
  // added overflow hidden because there was a second line getting added in firefox. 
  // for future, need a better way to handle long names - make all names longer? give this a second line in that case?
  overflow-x: hidden; // https://stackoverflow.com/questions/7695945/height-of-textarea-does-not-match-the-rows-in-firefox
  resize: none;
  background: white;
  outline: none;
`;

// Should this be combined wth formula cell view/share more common code? Right now they are very similar
/*
  Text Cells are different from other cells in that they don't have a label
*/
export const TextCellView = (props: CellViewProps) => {
  const startingTextValue = (props.cell.parameters as CalculationParameters)
    .formula.substring(TextCellFormulaPrefix.length + 1);
  const [text, setText] = useState(startingTextValue);
  const [lastSavedText, setLastSavedText] = useState(startingTextValue);
  const [setFocus, htmlElRef, setHtmlElSize] = useFocus();
  const [headerSize, setHeaderSize] = useState(0);
  // we don't want to allow this view to grab focus when we are converting it into a formula cell
  const [isConvertingToFormula, setIsConvertingToFormula] = useState(false);

  useEffect(() => {
    if (
      htmlElRef.current && props.setFocusTo.id == props.cell.id &&
      !isConvertingToFormula
    ) {
      setFocus(props.setFocusTo.position);
      props.onFocusSet();
    }
  }, [props.setFocusTo]);

  useEffect(() => {
    setHtmlElSize();
  }, [(props.cell.parameters as CalculationParameters).formula, headerSize]);

  useEffect(() => {
    let newHeaderSize = 0;
    while (text.length > newHeaderSize && text[newHeaderSize] == "#") {
      newHeaderSize++;
    }
    setHeaderSize(newHeaderSize);
  }, [text]);
  const textSizingClass = headerSize > 0 ? "text-header-" + headerSize : "";

  const saveText = () => {
    if (lastSavedText != text) {
      props.onUpdateParameters(CreateCalcParams(CreateTextCellFormula(text)));
      setLastSavedText(text);
    }
  };

  const textKeyDown = (e: KeyboardEvent, currentText: string) => {
    let el = e.target as HTMLTextAreaElement;
    switch (e.key) {
      case "Enter":
        props.onAddCellAfter(props.cell.id);
        e.preventDefault();
        break;
      case "=":
        // corner cases:
        // this could not be at the end of the label text (cursor is in the middle of the label)
        // not at end of label AND there is another equal later (so the formula is non-empty)
        //     ^ I'm ignoring this scenario for now, it feels unusual when I did it
        // immediately preceding this is a \
        // empty label

        // don't switch to formula when user types \=
        const previousCharacterIsBackslash =
          (el.selectionStart == 0 || text.length == 0)
            ? false
            : text[el.selectionStart - 1] == "\\";
        if (previousCharacterIsBackslash) return;
        // usually selectionStart will be after the last character, and this will return an empty string
        const textAfterSelection = text.substring(el.selectionStart);
        const textBeforeSelection = text.substring(0, el.selectionStart);
        e.preventDefault();
        // convert the text so far over to a label
        props.onUpdateLabel(textBeforeSelection);
        props.onUpdateParameters(CreateCalcParams(textAfterSelection)); // change formula from TEXTCELL to empty, so now user can edit the formula
        setIsConvertingToFormula(true);
        props.onCursorOut(props.cell.id, 0, "toFormula", "label");
        break;
      default:
        OnFormulaKeyDown(props, e, true, currentText);
        break;
    }
  };

  return (
    <TextInput
      ref={htmlElRef}
      value={text}
      onChange={(e) => {
        setText(e.target.value);
        InputAutoHeightAdjust(e.target as HTMLElement);
      }}
      onBlur={saveText}
      onKeyDown={(e) => textKeyDown(e, text)}
      rows={1}
      className={textSizingClass}
    />
  );
};
