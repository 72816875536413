import Decimal from "decimal.js";

// note that we are trying to share this file between
// deno and browser, and those have different import styles,
// so just keeping everything messy in one file for now

export const AppName = "Qrunch Studio";
export const AnonymousCellLabelPrefix = "Cell_";
export const TextCellFormulaPrefix = "TEXTCELL";

export const dateFormatString = "MMM d y";

///// layout ////

export type AfterCellId = CellId | "end" | "start";

////////////////////// Units ///////////////////////

export enum Measurement {
  Time = "time",
  Distance = "dist",
  Currency = "curr",
  Percent = "perc",
  None = "none",
}

export interface MeasurementDetail {
  displayAbbrev: "prefix" | "postfix" | "both";
}

/*
export const MeasurementDetails = new Map<Measurement, MeasurementDetail>([
  [Measurement.Distance, { displayAbbrev: "postfix" }],
  [Measurement.Currency, { displayAbbrev: "prefix" }],
  [Measurement.Percent, { displayAbbrev: "postfix" }],
]);
*/

export enum Si {
  None = "none",
  P = "P",
  T = "T",
  G = "G",
  M = "M",
  k = "k",
  h = "h",
  da = "da",
  d = "d",
  c = "c",
  m = "m",
  u = "u",
  n = "n",
  p = "p",
}

// to calculate the full value, use the following pseudo code equation:
// actualVal = num * 10 ^ SIMultiplierExponent
export const SiMultiplierExponent = new Map<Si, number>([
  [Si.P, 15],
  [Si.T, 12],
  [Si.G, 9],
  [Si.M, 6],
  [Si.k, 3],
  [Si.h, 2],
  [Si.da, 1],
  [Si.None, 0],
  [Si.d, -1],
  [Si.c, -2],
  [Si.m, -3],
  [Si.u, -6],
  [Si.n, -9],
  [Si.p, -12],
]);

export enum Unit {
  None = "none",

  // Currency
  Dollars = "$",
  Euro = "€",

  // Percent
  Percent = "%",

  // Distance
  Feet = "ft",
  Meters = "m",

  // Time
  Year = "yr",
  Month = "mo",
  Day = "day",
}

export const GetMeasurementFromUnit = (u: Unit): Measurement => {
  if (u == Unit.None || !u) return Measurement.None;
  const details = UnitDetails.get(u);
  if (!details) throw new Error("couldn't find unit details: " + u);
  return details.parent;
};

// any units where Si values can be applied
export const UnitsWithSi = [
  Unit.Dollars,
  Unit.Euro,
  Unit.Meters,
];

// any units that can be multiplied/divided by anything
// the result will always be the other unit (eg 5% * 100$ = $5)
export const UniversalUnits = [Unit.Percent, Unit.None];
export const isUniversalUnit = (u: Unit) => {
  return UniversalUnits.indexOf(u) != -1;
};

/*
export const ValueUnitMappings: Map<ValueType, Array<UnitAbbreviations>> = new Map([
  [ValueType.String, []],
  [ValueType.Number, []],
  [ValueType.Percent, [UnitAbbreviations.Percent]],
  [ValueType.Currency, [UnitAbbreviations.Dollars]],
  [ValueType.Distance, [UnitAbbreviations.Feet, UnitAbbreviations.Meters]]
]);
*/

export type DecimalPlaces = number; // number of decimal places to the right of the decimal. negative indicates right of decimal
// how many decimal places to show when the value does not have any decimal places?
export const DP_DEFAULT_WHEN_NO_DECIMALS = 0;
// how many decimal places to show in UI when the value *does* have decimal places?
export const DP_WHEN_DECIMALS_MAX_TO_DISPLAY = 2;
// currency is special and we will actually round the *underlying value* to this number of places
// when there are decimals
export const DP_WHEN_DECIMALS_CURRENCY_ROUND_TO = 2;

export type UnitDetail = {
  // abbrev: UnitAbbreviations;
  //fullName: string;
  parent: Measurement;
  baseConvertRate: number; // multiply values with this unit times baseConvertRate to get to the base rate.
} | { baseConvertRate: null; parent: Measurement.Percent | Measurement.Time };

export const UnitDetails = new Map<Unit, UnitDetail>([
  [Unit.Dollars, { baseConvertRate: 1.0, parent: Measurement.Currency }],
  [Unit.Euro, { baseConvertRate: 1.18, parent: Measurement.Currency }], // value as of Aug 2021. TODO: will need to update periodically??
  [Unit.Feet, { baseConvertRate: 0.3408, parent: Measurement.Distance }],
  [Unit.Meters, { baseConvertRate: 1.0, parent: Measurement.Distance }],
  [Unit.Percent, { baseConvertRate: null, parent: Measurement.Percent }],
  [Unit.Year, { baseConvertRate: null, parent: Measurement.Time }],
  [Unit.Month, { baseConvertRate: null, parent: Measurement.Time }],
  [Unit.Day, { baseConvertRate: null, parent: Measurement.Time }],
]);

/*
export interface CellDisplay {
  currentPrecision: Precision;
  currentUnit: Unit;
}
*/

// decimal only supports rounding to whole numbers, this allows rounding to a specific place
export const RoundDecimalToPlace = (d: Decimal, place: number): Decimal => {
  return d.toDecimalPlaces(place);
};

const placesBetweenDelimiters = 3;

export const GetNumberValueUserString = (
  val: NumberValue,
  forceDisplayPlaces?: number,
) => {
  if (
    forceDisplayPlaces !== undefined &&
    (Number.isNaN(forceDisplayPlaces) || typeof forceDisplayPlaces != "number")
  ) {
    throw new Error("Invalid forceDisplayPlaces: " + forceDisplayPlaces);
  }
  const displayPlaces = forceDisplayPlaces !== undefined
    ? forceDisplayPlaces
    : val.displayDecimalPlaces;
  let prefix = "";
  let postfix = "";
  if (GetMeasurementFromUnit(val.unit) == Measurement.Currency) {
    prefix = val.unit;
  }
  if (val.si != Si.None) {
    postfix = val.si;
  }
  if (
    val.unit != Unit.None &&
    GetMeasurementFromUnit(val.unit) != Measurement.Currency
  ) {
    postfix += val.unit;
  }
  let numToDisplay = val.numValue;
  if (val.unit == Unit.Percent) {
    numToDisplay = numToDisplay.mul(100);
  }
  // I'm not 100% sure it's the right thing to round to displayDecimalPlaces rather
  // than truncating to that. But it's what we're doing for now!
  let displayStr = RoundDecimalToPlace(numToDisplay, displayPlaces).toString();
  const rawStr = numToDisplay.toString();
  const isRounded = displayStr.length < rawStr.length &&
    displayPlaces < numToDisplay.decimalPlaces();

  const decimalIndex = displayStr.indexOf(".");
  const placesAfterDecimal = displayStr.length - decimalIndex - 1;
  const placesBeforeDecimal = decimalIndex == -1
    ? displayStr.length
    : decimalIndex;
  // if there are not enough decimal places after rounding, pad out to the proper length
  if (displayPlaces > 0) {
    let placesToAdd;
    if (decimalIndex == -1) {
      displayStr += ".";
      placesToAdd = displayPlaces;
    } else {
      placesToAdd = displayPlaces - placesAfterDecimal;
    }
    displayStr = displayStr.padEnd(displayStr.length + placesToAdd, "0");
  }

  // the plan to replace this is to add a years unit (eg. "1990yr")
  const looksKindOfLikeAYearHACK = numToDisplay.greaterThan(1950) &&
    numToDisplay.lessThan(2099);
  if (placesBeforeDecimal > 3 && !looksKindOfLikeAYearHACK) {
    // add _ delimiters
    // right now I'm not adding them after the decimal place, can do that later if we want it
    for (
      let index = placesBeforeDecimal - placesBetweenDelimiters;
      index > 0;
      index -= placesBetweenDelimiters
    ) {
      displayStr = displayStr.slice(0, index) + "_" +
        displayStr.slice(index, displayStr.length);
    }
  }
  return { str: prefix + displayStr + postfix, isRounded };
};

const monthIndexes: Record<string, number> = {
  "jan": 0,
  "feb": 1,
  "mar": 2,
  "apr": 3,
  "may": 4,
  "jun": 5,
  "jul": 6,
  "aug": 7,
  "sep": 8,
  "oct": 9,
  "nov": 10,
  "dec": 11,
};

export const getDateMonthIndex = (m: string): number => {
  const ret = monthIndexes[m.toLowerCase()];
  if (ret == null) {
    throw new Error(`unknown month: '${m}'`);
  }
  return ret;
};

export const getDateMonthString = (m: number): string => {
  for (const [k, v] of Object.entries(monthIndexes)) {
    if (v === m) {
      return k;
    }
  }
  throw new Error(`unknown month index: '${m}'`);
}

export const GetDateValueUserString = (
  val: DateValue,
) => {
  if (val.dateType === "date") {
  // can't import, so not using format return <>{format(new Date(val.millis), dateFormatString)}</>;
  const date = new Date(val.millis);
  return `${getDateMonthString(date.getMonth())}_${date.getDate()}_${date.getFullYear()}`
  } else {
    assertUnreachable(val.dateType);
  }
}

////////////////////// Value Types ///////////////////////

export type ValueId = number;

export enum ValueType {
  String = "str",
  Number = "num",
  Error = "err",
  Table = "tab",
  Vary = "var",
  Date = "dat",
}

export type AnyValue =
  | StringValue
  | ErrorValue
  | TableValue
  | NumberValue
  | VaryValue
  | DateValue;

export interface StringValue {
  type: ValueType.String;
  strValue: string;
  isUserEntered: boolean;
}

export interface ErrorValue {
  type: ValueType.Error;
  strValue: string;
  stack?: string;
}

export interface NumberValue {
  type: ValueType.Number;
  numValue: Decimal;
  unit: Unit;
  si: Si;
  displayDecimalPlaces: DecimalPlaces;
  isUserEntered: boolean;
}

export interface DateValue {
  type: ValueType.Date;
  // this is the number returned by .valueOf() on a Date
  millis: number;
  dateType: "date"; // | "month" | "DateTime" // don't have a year - that's just a number
  // timezone: ??; // let's skip timezone for now! but it will be needed for DateTime version
}

export type SubLabel = string;
// note that this re-uses the term cell: a qrunch Cell is the main
// thing in the worksheet, but a table cell is a sub-component of
// a TableCell - the thing at a particular row and column
export type ApiTableCell = { name: SubLabel; output: AnyValue };
export type TableRow = { index: number; columns: ApiTableCell[] };

export interface TableValue {
  valueId: ValueId;
  type: ValueType.Table;
  startPreviewRows: TableRow[];
  endPreviewRows: TableRow[];
  length: number;
}

// they are immediately dependent upon
export interface VaryOrigin {
  varyOutputId: ValueId;
  variationIndex: number;
  cellId: CellId;
}

// todo: reconcile this with the internal Variation - this should probably be the only version
// also, this version assumes only one value - for now that's all we need
export interface Variation {
  origins: VaryOrigin[];
  output: AnyValue;
}

export interface VaryValue {
  varyId: ValueId;
  type: ValueType.Vary;
  examples: Variation[];
  numVariations: number;
  // do we want to include # variations??
  isUserEntered: boolean;
}

export const IsValue = (x: any): boolean => {
  return !!(x.type);
};

export const CreateStringValue = (str: string): StringValue => ({
  type: ValueType.String,
  strValue: str,
  isUserEntered: true,
});

export const ERROR_VAL_STRING = "#ERROR";

export const CreateErrorValue = (msg?: string, stack?: string): ErrorValue => ({
  strValue: (msg && msg.startsWith(ERROR_VAL_STRING)) ? msg : `#ERROR: ${msg}`,
  type: ValueType.Error,
  stack,
});

/// Value Serialization

export const JsonDeserializeTableValue = (
  deserialized: TableValue,
): TableValue => {
  return {
    ...deserialized,
    startPreviewRows: deserialized.startPreviewRows.map(
      JsonDeserializeTableRow,
    ),
    endPreviewRows: deserialized.endPreviewRows.map(JsonDeserializeTableRow),
  };
};

export const JsonDeserializeTableRow = (row: TableRow) => {
  return {
    ...row,
    columns: row.columns.map((col) => {
      return { ...col, output: JsonFixDeserializedValue(col.output) };
    }),
  };
};

export const JsonFixDeserializedValue = (output: AnyValue): AnyValue => {
  switch (output.type) {
    case ValueType.Number:
      return { ...output, numValue: new Decimal(output.numValue) };
    case ValueType.Error:
    case ValueType.String:
    case ValueType.Date:
      return output;
    case ValueType.Vary:
      // TODO: have not actually tested this! If you are in the future (you are!) and varies
      // are working fine with large decimal values, then you can remove this comment
      return {
        ...output,
        examples: output.examples.map((example) => {
          return {
            ...example,
            output: JsonFixDeserializedValue(example.output),
          };
        }),
      };
    case ValueType.Table:
      // TODO: have not actually tested this! If you are in the future (you are!) and varies
      // are working fine with large decimal values, then you can remove this
      return {
        ...output,
        startPreviewRows: output.startPreviewRows.map(
          JsonDeserializeTableRow,
        ),
        endPreviewRows: output.endPreviewRows.map(
          JsonDeserializeTableRow,
        ),
      };
    default:
      assertUnreachable(output);
  }
  throw new Error("unreachable");
};

//////////////////// Parameter Types ////////////////////
export enum ParameterType {
  Calculation = "calc",
  Table = "table",
  Vary = "vary",
}

export type Column = {
  name: string;
  formula: string;
  initial?: string;
};

export type LengthStatement = number | string;

export interface TableParameters {
  type: ParameterType.Table;
  length: LengthStatement;
  columns: Array<Column>;
}

export interface VaryParameters {
  type: ParameterType.Vary;
  formula: string;
}

export interface CalculationParameters {
  type: ParameterType.Calculation;
  formula: string;
}

export type CellParameters =
  | TableParameters
  | VaryParameters
  | CalculationParameters;

export const CreateVaryParams = (formula: string): VaryParameters => ({
  type: ParameterType.Vary,
  formula,
});

export const CreateCalcParams = (formula: string): CalculationParameters => {
  return { type: ParameterType.Calculation, formula };
};

export const CreateTextParams = () => {
  return CreateCalcParams(TextCellFormulaPrefix);
};

export const CreateNewColumn = () => ({ name: "", formula: "" });

export const CreateTableParams = (
  length?: LengthStatement,
  columns?: Array<Column>,
): TableParameters => {
  if (length === undefined) {
    length = 5;
  }
  if (columns === undefined) {
    columns = [CreateNewColumn()];
  }
  return {
    type: ParameterType.Table,
    length,
    columns,
  };
};

////////////////////// Actions  ///////////////////////
// actions are things that users (clients) do to change the worksheets
export enum ActionNames {
  DELETE_CELL = "delete_cell",
  UPDATE_CELL_PARAMETERS = "update_cell_parameters",
  UPDATE_CELL_LABEL = "update_cell_label",
  ADD_CELL = "add_cell",
  REQUEST_INIT_CLIENT = "request_init_client",
  MOVE_CELL = "move_cell",
}

// note this should not be sent by clients
export type AddCellAction = {
  name: ActionNames.ADD_CELL;
  parameters: CellParameters;
  clientCreationId: CellId;
  // if the client needs to dictate the server stored id.
  forceId: CellId | undefined;
} & AddCellLayoutInfo;

// not a full action, this is the information attached
// to add cell that tells layout what to do
export type AddCellLayoutInfo = {
  afterCellId: CellId | "end" | "start";
};

export interface UpdateCellParametersAction {
  name: ActionNames.UPDATE_CELL_PARAMETERS;
  id: CellId;
  parameters: CellParameters;
}

export interface UpdateCellLabelAction {
  name: ActionNames.UPDATE_CELL_LABEL;
  id: CellId;
  newLabel: string;
}

export interface DeleteCellAction {
  name: ActionNames.DELETE_CELL;
  id: CellId;
}

export interface MoveCellAction {
  name: ActionNames.MOVE_CELL;
  id: CellId;
  direction: "up" | "down";
}

export interface RequestInitClientAction {
  name: ActionNames.REQUEST_INIT_CLIENT;
}

export type Action = CellAction | MetaAction | LayoutAction;
export type CellAction =
  | AddCellAction
  | UpdateCellParametersAction
  | UpdateCellLabelAction
  | DeleteCellAction;
export type MetaAction = RequestInitClientAction;
export type LayoutAction = MoveCellAction;

//////////////////////// Events ////////////////////////
// Events are things that the server sends to clients to tell them about changes to the worksheet
export type ServerResponseEvent =
  | CellUpdatedEvent
  | CellAddedEvent
  | CellDeletedEvent
  | LayoutUpdatedEvent
  | ErrorEvent;
export type MetaEvent = InitClientEvent | DisconnectEvent;

export enum EventNames {
  INIT_CLIENT =
    "init_client", /* client should forget everything it knows and start fresh with this data. */
  CELL_UPDATED = "cell_updated",
  CELL_ADDED = "cell_added",
  ERROR = "error",
  CELL_DELETED = "cell_deleted",
  LAYOUT_UPDATED = "layout_updated",
  DISCONNECT = "disconnect"
}

export class ResponseEventConstructor {
  static CellAddedInternal(
    label: string,
    parameters: CellParameters,
    id: CellId,
    clientCreationId: CellId,
  ): Omit<CellAddedEvent, "order"> {
    return {
      name: EventNames.CELL_ADDED,
      label,
      parameters,
      id,
      clientCreationId,
    };
  }

  static CellDeleted(id: CellId): Omit<CellDeletedEvent, "order"> {
    return {
      name: EventNames.CELL_DELETED,
      id,
    };
  }
}

// why use string instead of bigint? bigints can't be easily serialized in JSON
// so they are pain in the neck. So let's just store them as strings
export type CellId = string;

export interface Cell {
  id: CellId;
  label: string;
  parameters: CellParameters;
  output: AnyValue;
}

export type InitClientEventInternal = {
  name: EventNames.INIT_CLIENT;
  cells: Array<Cell>;
};

export type InitClientEvent = InitClientEventInternal & LayoutUpdatedInfo;

export type DisconnectEvent = {
  name: EventNames.DISCONNECT;
}

export interface CellUpdatedEvent {
  name: EventNames.CELL_UPDATED;
  id: CellId;
  newLabel: string;
  parameters: CellParameters; 
  output: AnyValue;
}

export type LayoutUpdatedEvent = {
  name: EventNames.LAYOUT_UPDATED;
} & LayoutUpdatedInfo;

export type LayoutUpdatedInfo = {
  order: Array<CellId>;
};
export type CellAddedEventInternal = {
  name: EventNames.CELL_ADDED;
  label: string;
  parameters: CellParameters;
  // doesn't include output since that can cause updates to fire
  // specifically for tables with columns that depend on each other
  id: CellId;
  clientCreationId: CellId;
};

// we include layout info since it changes layout
export type CellAddedEvent = CellAddedEventInternal & LayoutUpdatedInfo;

export interface ErrorEvent {
  name: EventNames.ERROR;
  id: CellId; // the cell the error is associated - can be null to describe general angst
  description: string; // text to show the user
}

export type CellDeletedEventInternal = {
  name: EventNames.CELL_DELETED;
  id: CellId;
};

// we include layout info since it changes layout
export type CellDeletedEvent = CellDeletedEventInternal & LayoutUpdatedInfo;

export interface WorksheetListener {
  onEvent(event: ServerResponseEvent): void;
}

export type WorksheetId = bigint;

/// helper functions
export function assertUnreachable(valA: never): never {
  throw new Error("reached assertUnreachable");
}

///////////// API Responses //////////

export type GetTableRowsResponse = {
  rows: TableRow[];
};

export type GetVariationResponse = {
  variation: Variation;
};

export type GetCellLabelByIdResponse = {
  cellLabel: string;
};
